export const FirebaseConfig = {
	"projectId": "cab-booking-82ecc",
	"appId": "1:194541707604:web:f8ff2a8987a6af103ae8c1",
	"databaseURL": "https://cab-booking-82ecc-default-rtdb.firebaseio.com",
	"storageBucket": "cab-booking-82ecc.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDNX9GLceKe_Ez_F7mtb1PD7CH9-UzKTvI",
	"authDomain": "cab-booking-82ecc.firebaseapp.com",
	"messagingSenderId": "194541707604",
	"measurementId": "G-BZF23XZ3M4"
};